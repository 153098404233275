
import React from "react";
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticlePreview from "../components/articlePreview";


const ScrapBook = ({ data }) => {
  const articles = data.allNodeArticle.nodes;

  return (
    <Layout>
      <SEO title="Kalyan Feeds" />
<div className='row backNavGreen'>
    <div className="col-md-12" id="navCrumb">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                                <div className="row" id="breadCrumb">
                                <div className="col-12">  <span>
                                        <Link
                                        to="/"  >
                                   Home
                                        </Link>
                                        &nbsp;/&nbsp;
                                        <Link
                                        to="/scrapbook"  >
                                   Scrapbook
                                        </Link>
                                        </span>
                                        </div>
                                </div>
                                <div className="row" id="headCrumb">
                                <div className="col-12">   <h4>ScrapBook</h4>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>

      <div className="container inner-blog-page">
            <div className="row">

            <div className='col-md-12'>
              <h2> Coming Soon ... </h2>
            </div>
              
      {/* {articles.map(article => (
          <div className="col-md-3">
        <ArticlePreview
          key={article.id}
          title={article.title}
          path={article.path.alias}
          image={article.relationships.field_image.localFile.childImageSharp.fluid}
          alt={article.field_image.alt}
          tag={article.relationships.field_tags[0].name}
          summary={article.body.summary ? article.body.summary : article.body.processed.substring(0, 300)}
        />
         </div>
      ))}  */}
    </div>
    </div>
    </Layout>
    
  );
};

ScrapBook.propTypes = {
  data: PropTypes.object.isRequired,
};

export const data = graphql`
 {
    allNodeArticle(limit: 1000, filter: {relationships: {field_tags: {elemMatch: {name: {eq: "Wedding"}}}}}) {
     nodes {
       id
       title
       created
       body {
         processed
         summary
       }
       path {
         alias
       }
       field_image {
         alt
       }
       relationships {
        field_tags {
          ... on taxonomy_term__kalyan_shastra_categories {
            id
            name
          }
        }
         field_image {
           localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
              }
            }
           }
         }
       }
     }
   },
   
 }
`;

export default ScrapBook;